import "./aframe";
import { setupScene } from "./scene";
import _ from "lodash";
import "./lib/animation-mixer";
import "./lib/fbx-model";

const server = process.env.SERVER || "https://msc-ar.com";
const showMSC = process.env.SHOW_MSC || "visible";

function checkShowMSC() {
  if (showMSC !== "hidden") {
    $("#msc-logo").show();
    $("#index-title-1").show();
    $("#index-title-3").show();
  }
}
checkShowMSC();

function showWarning(...props) {
  for (const text of props.reverse()) {
    if (text) {
      $("#warning-text").prepend(
        `<p class="text-base text-white font-medium tracking-wide">${text}</p>`
      );
    }
  }

  $("#warning").show();
}

// 添加輝哥的瀏覽器提示
// https://github.com/withcloud/mindar-congratulations-picture-demo/blob/browser/src/index.html
const browser = {
  // 版本信息
  versions: (function() {
    var u = navigator.userAgent;
    var app = navigator.appVersion;
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
  // 打開對象
  openObject: (function() {
    var ua = navigator.userAgent;
    var u = navigator.userAgent.toLowerCase(); //获取判断用的对象
    return {
      weixin: u.match(/MicroMessenger/i) == "micromessenger",
      qq: u.match(/QQ/i) == "qq",
      weibo: u.match(/WeiBo/i) == "weibo",
      alipay: /alipay/gi.test(u),
      dingtalk: u.indexOf("dingtalk") !== -1,
      chrome: u.indexOf("Chrome") > -1,
      isChrome: /(?:Chrome|CriOS)/.test(ua),
      isSafari: /Safari/.test(ua), //蘋果內置瀏覽器  百度
      baidui: u.indexOf("baidu") > 0,
      ios: /iphone|ipod|ipad/.test(u)
    };
  })()
};

// 微信檢查
// 偵測到微信時會彈 alert
// 移動端
if (
  /Android|Adr|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  //非ios處理  chrome  safari weixin
  if (browser.versions.mobile) {
    if (browser.openObject.weixin) {
      showWarning(
        "點擊右上角「三個點」按鈕，並選擇「在預設瀏覽器中打開」。",
        "或是複製以下網址，並到瀏覽器APP (如 Chrome, Safari)中貼上並開啟："
      );
    } else if (
      browser.openObject.dingtalk ||
      browser.openObject.qq ||
      browser.openObject.baidui
    ) {
      // do nothing
    } else {
      // do nothing
    }
  }
} else {
  // pc端
  // do nothing
}

// 檢查webgl 支援度
function webglSupport() {
  if (window.WebGLRenderingContext) {
    var canvas = document.createElement("canvas");
    var context =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

    if (context && context instanceof WebGLRenderingContext) {
      // ok
    } else {
      showWarning(
        "抱歉，您的瀏覽器不支持 WebGL。",
        "請複製以下網址，並到瀏覽器APP (如 Chrome, Safari)中貼上並開啟："
      );
    }
  } else {
    showWarning();
  }
}
webglSupport();

// 添加屏幕轉向監控
const screenDirection = window.innerHeight > window.innerWidth;
setInterval(() => {
  if (screenDirection !== window.innerHeight > window.innerWidth) {
    window.location.reload();
  }
}, 1000);

//////////////////////////

const url = new URL(window.location.href);
let id = url.pathname.split("/")[1];

// 本機我想這樣拿 id /?id=xxxx
const urlParams = new URLSearchParams(window.location.search);
id = urlParams.get("id") || id;
console.log("id", id);

if (!id) {
  alert("no id found");
}

let data = {};

$(document).ready(async function() {
  let item = null;
  let error = null;
  // 利用 API 讀取必要的資料, 用 id 取得資料

  try {
    const url = server + `/api/item?itemId=${id}`;
    const response = await fetch(url, {
      credentials: "include",
      mode: "cors",
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": server
      }
    });
    const result = await response.json();

    item = result?.item;
    error = result?.error;

    if (item) {
      let visible = true;
      if (!item.published) {
        alert("這個項目未發佈，請先到MSC-AR頁面進行發佈。");
        visible = false;
      } else if (item.state !== "ready") {
        alert("這個項目未審批，若要審批請到MSC-AR頁面提交審批。");
      }

      $("#item-name").text(item.name);
      if (visible) {
        $("#start-btn").css("visibility", "visible");
      }
    } else {
      if (error) {
        throw new Error(error);
      }
    }
  } catch (error) {
    if (error.message === "item not ready") {
      alert("這個項目未審批，只有創建者可以察看。");
      window.location.replace(server + `/login?b=${id}`);
    } else {
      alert(error.message);
    }
  }

  $("#start-btn").on("click", async function() {
    // 防止重複點擊
    const $btn = $(this);
    if ($btn.prop("disabled")) return;
    $btn.prop("disabled", true);

    if (!item) {
      $btn.prop("disabled", false);
      return;
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      showWarning(
        "攝像頭不能啟用：這可能由於你沒有同意瀏覽器使用攝像頭的權限，或是使用非系統預設的瀏覽器開啟此頁面。",
        "請關閉並重開瀏覽器，並允許此網頁使用攝像頭權限。",
        "或是複製以下網址，並到瀏覽器APP (如 Chrome, Safari)中貼上並開啟："
      );
      $btn.prop("disabled", false);
      return;
    }

    try {
      const video = document.createElement("video");
      const checkCamera = await navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
            width: { ideal: 640 },
            height: { ideal: 480 }
          }
        })
        .then(stream => {
          video.addEventListener("loadedmetadata", () => {
            console.log("video ready...", video);
            console.log("width", video.videoWidth);
            console.log("height", video.videoHeight);

            // 移除 video
            // 測試完畢後，立即關閉流
            stream.getTracks().forEach(track => track.stop());
            // 移除 video 元素
            video.remove();

            $("#intro").hide();
            start(item);
          });
          video.srcObject = stream;
        })
        .catch(() => {
          showWarning(
            "攝像頭不能啟用：這可能由於你沒有同意瀏覽器使用攝像頭的權限，或是使用非系統預設的瀏覽器開啟此頁面。",
            "請關閉並重開瀏覽器，並允許此網頁使用攝像頭權限。",
            "或是複製以下網址，並到瀏覽器APP (如 Chrome, Safari)中貼上並開啟："
          );
          $btn.prop("disabled", false);
        });
    } catch (error) {
      console.error("Camera error:", error);
      $btn.prop("disabled", false);
    }
  });
});

async function handleResource(type, resource, index) {
  let asset = null;
  let entity = null;
  let vid = null;
  let scaleW = 1;
  let scaleH = 1;
  let promise = null;
  let size = null;

  let fileType = null;
  let assetModel = null;
  let modelSrc = null;
  let modelType = null;

  switch (resource?.output) {
    case "3d-model":
      if (!resource.model) return null;
      fileType = resource.model.split(".").pop();
      switch (fileType.toLowerCase()) {
        case "glb":
        case "gltf":
          modelSrc = `#${resource.id}`;
          modelType = "gltf-model";
          assetModel = resource.model;
          break;
        case "fbx":
          modelSrc = `src: url(${resource.model}); crossorigin: anonymous;`;
          modelType = "fbx-model";
          assetModel = "";
          break;
        default:
          break;
      }

      asset = {
        id: resource.id,
        type: "item",
        src: assetModel
      };

      entity = {
        children: [
          {
            type: modelType,
            id: resource.id + "entity",
            rotation: resource.rotation || "0 0 0",
            scale: resource.scale || "1 1 1",
            position: resource.position || "0 0 0",
            src: modelSrc,
            "animation-mixer": ""
          }
        ]
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;
    case "video":
      if (!resource.video) return null;
      if (resource.videoWidth > 0 && resource.videoHeight > 0) {
        size = { width: resource.videoWidth, height: resource.videoHeight };
      } else {
        vid = document.createElement("video");
        vid.src = resource.video + `?t=${Date.now()}`;
        vid.crossOrigin = "Anonymous";

        promise = new Promise(resolve => {
          vid.addEventListener(
            "loadedmetadata",
            function() {
              resolve({ width: this.videoWidth, height: this.videoHeight });
            },
            false
          );
        });

        size = await promise.then();
      }

      if (size?.width > size?.height) {
        scaleW = size?.width / size?.height;
      } else if (size?.width < size?.height) {
        scaleH = size?.height / size?.width;
      }

      asset = {
        id: resource.id,
        type: resource.output,
        src: resource.video + `?t=${Date.now()}`,
        muted: true,
        loop: true
      };
      entity = {
        children: [
          {
            type: "video",
            id: resource.id + "entity",
            src: `#${resource.id}`,
            loop: "true",
            width: (scaleW * resource.mediaScale).toString(),
            height: (scaleH * resource.mediaScale).toString()
          }
        ]
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;

    case "audio":
      if (!resource.audio) return null;
      asset = {
        id: resource.id,
        type: resource.output,
        src: resource.audio,
        loop: "true",
        muted: true
      };
      entity = {
        children: [
          {
            type: "sound",
            id: resource.id + "entity",
            src: `#${resource.id}`,
            loop: "true"
          }
        ]
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;
    case "image":
      if (!resource.image) return null;
      if (resource.imageWidth > 0 && resource.imageHeight > 0) {
        size = { width: resource.imageWidth, height: resource.imageHeight };
      } else {
        promise = new Promise(resolve => {
          const img = new Image();
          img.src = resource.image + `?t=${Date.now()}`;
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
        });

        size = await promise.then();
      }

      if (size?.width > size?.height) {
        scaleW = size?.width / size?.height;
      } else if (size?.width < size?.height) {
        scaleH = size?.height / size?.width;
      }

      asset = {
        id: resource.id,
        type: "img",
        src: resource.image + `?t=${Date.now()}`
      };
      entity = {
        children: [
          {
            type: "image",
            id: resource.id + "entity",
            src: `#${resource.id}`,
            loop: "true",
            transparent: "true",
            width: (scaleW * resource.mediaScale).toString(),
            height: (scaleH * resource.mediaScale).toString()
          }
        ]
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;
    case "response":
      if (!resource.response) return null;
      entity = {
        "question-option": `id: ${resource.id}; text: ${resource.response}`
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;
    case "link":
      if (!resource.link) return null;
      entity = {
        "link-modal": `url: ${resource.link}; title: ${resource.link}};`
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;
    case "question":
      entity = {
        "question-option": `
          id: ${resource.id}; 
          text: ${resource.question}; 
          options: ${(_.values(resource.options) || ["", "", "", ""]).join(
            ","
          )};
          answer: ${resource.answer};
          showAnswer: ${resource.showAnswer};
        `
      };

      if (type === "marker") {
        entity["mindar-image-target"] = `targetIndex: ${index}`;
      } else if (type === "location") {
        entity[
          "gps-target"
        ] = `lat: ${resource.location?.lat}; long: ${resource.location?.lon}`;
      }
      break;
    default:
      break;
  }

  return { asset, entity };
}

async function start(item) {
  if (item) {
    const resources = item?.resources || [];
    let markers = [];

    data.scene = {};
    data.assets = [];
    data.entities = [];
    data.title = item.name;

    if (item.content) {
      data.scene.question = `id: ${item.id}; text: ${item.content}`;
    }

    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      const content = await handleResource(resource.mode, resource, i);

      if (content?.asset) data.assets.push(content?.asset);
      if (content?.entity) data.entities.push(content?.entity);
      if (content && resource.markerOutput) markers.push(resource.markerOutput);
    }

    data.scene["mindar-image"] = `imageTargetSrc: ${
      markers.length > 0 ? markers.join(", ") : item.markerCompiled
    }; filterMinCF:0.0001; filterBeta: 0.001`;
  }

  // 建立 aframe 場景
  await setupScene(data);
}
